<template>
 <div>
  <svg id="Layer_1" style="enable-background:new 0 0 183 183;" version="1.1" viewBox="0 0 183 183" x="0px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" y="0px">
   <defs>
    <linearGradient gradientTransform="matrix(1 0 0 -1 0 184)" gradientUnits="userSpaceOnUse" id="icon-book-2_00000137822579641261687070000005085083731620698246_" x1="147.8314" x2="44.1794" y1="135.508" y2="48.5364">
     <stop stop-color="#F5102A" offset="0"/>
     <stop stop-color="#D10101" offset="1"/>
    </linearGradient>
   </defs>
   <g id="Laag_2">
    <g id="Laag_1-2">
     <g id="icon-book">
      <rect :fill="background" height="183" width="183"/>
      <linearGradient gradientTransform="matrix(1 0 0 -1 0 184)" gradientUnits="userSpaceOnUse" id="icon-book-2_00000137822579641261687070000005085083731620698246_" x1="147.8314" x2="44.1794" y1="135.508" y2="48.5364">
       <stop offset="2.273479e-04" style="stop-color:#F5102A"/>
       <stop offset="1" style="stop-color:#D10101"/>
      </linearGradient>
      <path d="M140.1,114.2     V41.2c0-2.9-2.3-5.2-5.2-5.2c0,0,0,0,0,0H63.7c-11.5,0-20.8,9.3-20.8,20.9v0v69.5c0,11.5,9.3,20.8,20.8,20.8h71.2     c2.9,0,5.2-2.3,5.2-5.2v-3.5c0-1.6-0.7-3.1-1.9-4.1c-0.9-3.3-0.9-12.9,0-16.2C139.4,117.2,140.1,115.7,140.1,114.2z M70.7,65.1     c0-0.7,0.6-1.3,1.3-1.3c0,0,0,0,0,0h46c0.7,0,1.3,0.6,1.3,1.3v4.3c0,0.7-0.6,1.3-1.3,1.3h0H72c-0.7,0-1.3-0.6-1.3-1.3v0     L70.7,65.1z M70.7,79c0-0.7,0.6-1.3,1.3-1.3c0,0,0,0,0,0h46c0.7,0,1.3,0.6,1.3,1.3v0v4.3c0,0.7-0.6,1.3-1.3,1.3H72     c-0.7,0-1.3-0.6-1.3-1.3L70.7,79z M125.7,133.3h-62c-3.8-0.1-6.8-3.4-6.7-7.2c0.1-3.6,3-6.5,6.7-6.7h62     C125.2,124,125.2,128.6,125.7,133.3z" id="icon-book-2" style="fill:url(#icon-book-2_00000137822579641261687070000005085083731620698246_);"/>
     </g>
    </g>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>